<template>
  <!-- <el-tab-pane label="Товары" name="second"> -->
  <div class="product-add">
    <div class="product-add-table mt-5">
      <table class="table-my-code table-bordered borde-l-r" v-loading="loadingData">
        <el-table :data="dealProductsList" style="width: 100%">
          <el-table-column label="Name" width="180">
            <template slot-scope="scope">
              {{ scope.row.product.name }}
            </template>
          </el-table-column>
          <el-table-column label="quantity">
            <template slot-scope="scope">
              {{ scope.row.quantity }}

            </template>
          </el-table-column>
          <el-table-column label="measurement">
            <template slot-scope="scope">
              {{ scope.row.product.measurement.name }}
            </template>
          </el-table-column>
          <el-table-column label="price">
            <template slot-scope="scope">
              {{ scope.row.price }}

            </template>
          </el-table-column>
          <el-table-column label="currency">
            <template slot-scope="scope">
              {{ scope.row.currency ? scope.row.currency.symbol : '' }}
   
            </template>
          </el-table-column>

          <el-table-column label="discount">
            <template slot-scope="scope">
              {{ scope.row.discount }}

            </template>
          </el-table-column>
          <el-table-column label="nds">
            <template slot-scope="scope">
              {{ scope.row.nds }}
     
            </template>
          </el-table-column>
          <el-table-column label="total">
            <template slot-scope="scope">
              {{ productRowTotal(scope.row) }}
            </template>
          </el-table-column>
        </el-table>

      </table>
    </div>
    <el-col :span="24" class="mt-2">
      <el-row>
        <el-col :span="12" :offset="12">
          <div class="table-responsive">
            <table class="table borderless">
              <tbody>
                <tr>
                  <th style="width: 50%">
                    {{ $t("message.Amount without discount and NDS") }}:
                  </th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ totalAmountWithoutNDSAndDiscount }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.Discount amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ discountAmount }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.NDS amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ NDSAmount }}
                    </h4>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("message.Total amount") }}:</th>
                  <td>
                    <h4 class="font-weight-bold">
                      {{ totalAmount }}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
    </el-col>
  </div>
  <!-- </el-tab-pane> -->
</template>

<script>
import selectFilialProduct from "@/components/inventory/select-filial-product";
import selectCurrency from "@/components/inventory/select-currency";

import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";

export default {
  components: {
    selectFilialProduct,
    selectCurrency,
  },
  mixins: [show, form, drawer],
  props: {
    filial_id: {
      default: null,
    },
    deal_id: {
      default: null,
    },
  },
  data() {
    return {
      dealProductsList: [],
      product_id: null,
      input: "",
      value: "",
      options: {},
      num: 1,
      updateProducts: false,
      centerDialogVisible: false,
    };
  },
  watch: {
    deal_id: {
      handler: function (deal_id) {
        if (deal_id) {
          if (this.deal_id) {
            this.updateList();
          }
        }
      },
      immediate: true,
      deep: true,
    },
    product_id: {
      handler: function (product_id) {
        if (this.dealProductsList.length !== 0) {
          if (
            this.dealProductsList.filter((e) => e.product_id === product_id)
              .length <= 0
          ) {
            this.addToDealProductsList(product_id);
          }
        }
        if (product_id && this.dealProductsList.length === 0) {
          this.addToDealProductsList(product_id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
    }),

    totalAmountWithoutNDSAndDiscount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += product.quantity * product.price;
      });
      return Number(total.toFixed(2));
    },
    discountAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (product.quantity * product.price * product.discount) / 100;
      });
      return Number(total.toFixed(2));
    },
    NDSAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total += (product.quantity * product.price * product.nds) / 100;
      });
      return Number(total.toFixed(2));
    },
    totalAmount: function () {
      let total = 0;
      this.dealProductsList.forEach((product) => {
        total +=
          product.quantity *
          product.price *
          (1 - product.discount / 100 + product.nds / 100);
      });
      return Number(total.toFixed(2));
    },
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      show: "dealProducts/show",
      showProduct: "products/show",
      empty: "dealProducts/empty",
      destroy: "dealProducts/destroy",
    }),

    handleChange() {},
    dealOffersCreate() {
        if(this.dealProductsList.length > 0){
            this.$emit('dealOffersCreate', {drawer: true, productList: this.dealProductsList})
        }else{
            this.$message({
                type: "warning",
                message: this.$t('message.product_list_is_empty'),
            });
        }
    },
    productRowTotal(product) {
      let rowTotal =
        product.price *
        product.quantity *
        (1 - product.discount / 100 + product.nds / 100);
      return Number(rowTotal.toFixed(2));
    },
    updateList(){
       this.loadingData = true;
        this.show(this.deal_id)
            .then((res) => {
            this.dealProductsList = JSON.parse(JSON.stringify(this.dealProducts));
            this.updateProducts = true;
            this.loadingData = false;
            })
            .catch((err) => {
            this.loadingData = false;
            });
    }

  },
};
</script>


<style>
</style>
